<template>
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00011 0.438368L4.44455 4.88281L7.11122 4.88281L7.11122 12.8828L8.889 12.8828L8.889 4.88281L11.5557 4.88281L8.00011 0.438368Z"
      fill="#16ACD9"
    />
    <path
      d="M14.2222 6.88281H10.6667V8.66059H14.2222V16.6606H1.77778V8.66059H5.33333V6.88281H1.77778C0.797333 6.88281 0 7.68015 0 8.66059V16.6606C0 17.641 0.797333 18.4384 1.77778 18.4384H14.2222C15.2027 18.4384 16 17.641 16 16.6606V8.66059C16 7.68015 15.2027 6.88281 14.2222 6.88281Z"
      fill="#16ACD9"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadIcon',
}
</script>
