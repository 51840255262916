<template>
  <div class="w-100">
    <div
      v-if="loading"
      class="spinner-block"
    >
      <b-spinner />
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col-lg-6 col-xs-12 pe-lg-2 d-flex flex-column">
        <div class="w-100 card-container">
          <k-card class="w-100 k-card-custom-class">
            <edit-details
              @update-message="updateMessage"
            />
          </k-card>
        </div>
        <div class="w-100 card-container">
          <k-card class="w-100 k-card-custom-class">
            <edit-packing-list-details
              :packing-info-details="packingInfo"
              :load-shipment-details="loadShipmentDetails"
            />
          </k-card>
        </div>
      </div>
      <div class="col-lg-6 col-xs-12 ps-lg-2 d-flex flex-column">
        <div class="w-100 card-container">
          <k-card class="w-100 k-card-custom-class">
            <edit-addresses />
          </k-card>
        </div>
        <div class="w-100 card-container">
          <k-card class="w-100 k-card-custom-class">
            <document-list />
          </k-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { KCard } from '@kingpin-global/kingpin-ui'
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import { FETCH_SHIPMENT, PATCH_SHIPMENT, SET_EDITING_SHIPMENT } from '@/store/modules/shipment.module'
import { mapState } from 'vuex'
import { SET_CURRENT_ORDER_PRODUCTS } from '@/store/modules/product.module'
import EditDetails from './EditDetails.vue'
import EditAddresses from './EditAddresses.vue'
import EditPackingListDetails from './EditPackingListDetails.vue'
import DocumentList from './DocumentList.vue'
import { getUpdateShipmentPayload } from './shipment-management-utils'

export default {
  name: 'ShipmentDetailsTab',
  components: {
    KCard,
    EditDetails,
    EditAddresses,
    EditPackingListDetails,
    DocumentList,
  },
  data() {
    return {
      shipmentId: this.$route.params.shipmentId,
      shipmentData: {},
      rawShipmentData: {},
      payload: {},
      loading: true,
      packingInfo: {},
    }
  },
  computed: {
    ...mapState({
      editingShipment: state => state.shipments.editingShipment,
    }),
  },
  emits: ['on-updated'],
  watch: {
    editingShipment: {
      handler(val) {
        this.setPayload(val)
      },
      deep: true,
    },
    $route(to, from) {
      if (to.params.shipmentId !== from.params.shipmentId) {
        this.shipmentId = to.params.shipmentId
        this.loadShipmentDetails()
      }
    },
  },
  created() {
    this.$root.$refs.ShipmentDetailsTab = this
    this.loadShipmentDetails()
  },
  methods: {
    loadShipmentDetails() {
      this.$store.dispatch(FETCH_SHIPMENT, this.shipmentId)
        .then(res => {
          this.rawShipmentData = JSON.parse(JSON.stringify(res.data.data))
          this.shipmentData = res.data.data
          this.packingInfo = this.shipmentData?.packingInfo || {}
          this.$store.commit(SET_EDITING_SHIPMENT, this.shipmentData)
          this.$store.commit(SET_CURRENT_ORDER_PRODUCTS, this.shipmentData.products)
          this.loading = false
        })
        .catch(err => {
          apiToastErrorV2(err)
          this.loading = false
        })
    },
    setPayload(shipment) {
      this.payload = getUpdateShipmentPayload(this.rawShipmentData, shipment)
    },
    updateShipment() {
      this.$store.dispatch(PATCH_SHIPMENT, { shipmentId: this.shipmentId, payload: this.payload })
        .then(() => {
          apiToastSuccessV2('Changes have been saved!')
          this.$emit('on-updated')
          this.loadShipmentDetails()
        })
        .catch(err => {
          apiToastErrorV2(err)
          this.$emit('on-updated')
        })
    },
    updateMessage() {
      this.$emit('on-updated')
      apiToastErrorV2('Please enter the date')
    },
  },
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/pages/kp-details-tab.scss";
</style>
