<template>
  <div v-if="editingShipment">
    <div
      v-b-toggle.collapse-edit-addresses
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span> Addresses </span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse-edit-addresses"
      v-model="isVisible"
      class="card-body-container"
    >
      <!-- Brand's Billing Address -->
      <div>
        <div class="content cursor-pointer mb-2">
          <span
            class="d-flex align-items-center"
            @click="brandBillingAddressCollapse = !brandBillingAddressCollapse"
          >
            <caret-svg-icon
              :class="brandBillingAddressCollapse ? 'down' : 'right'"
            />
            <span class="address-label font-poppins">
              Brand’s Billing Address
            </span>
          </span>
        </div>
        <b-collapse
          id="bba"
          :visible="brandBillingAddressCollapse"
        >
          <div>
            <address-form
              :countries="countries"
              :address-type="ADDRESSES_TYPE.BILLING"
              :address-data="editingShipment.billingAddressForBrand"
              :disabled-all-fields="true"
            />
          </div>
        </b-collapse>
      </div>

      <!-- Brand's shipping address -->
      <div class="mt-3">
        <div class="content cursor-pointer mb-2">
          <span
            class="d-flex align-items-center"
            @click="
              brandShippingAddressCollapse = !brandShippingAddressCollapse
            "
          >
            <caret-svg-icon
              :class="brandShippingAddressCollapse ? 'down' : 'right'"
            />
            <span class="address-label font-poppins">
              Brand’s Shipping Address
            </span>
          </span>
        </div>
        <b-collapse
          id="bsa"
          :visible="brandShippingAddressCollapse"
        >
          <div>
            <address-form
              :countries="countries"
              :address-type="ADDRESSES_TYPE.SHIPPING"
              :address-data="editingShipment.shippingAddressForBrand"
              :disabled-all-fields="true"
            />
          </div>
        </b-collapse>
      </div>

      <!-- Retailer's billing address -->
      <div class="mt-3">
        <div class="content cursor-pointer mb-2">
          <span
            class="d-flex align-items-center"
            @click="
              retailerBillingAddressCollapse = !retailerBillingAddressCollapse
            "
          >
            <caret-svg-icon
              :class="retailerBillingAddressCollapse ? 'down' : 'right'"
            />
            <span class="address-label font-poppins">
              Retailer's Billing Address
            </span>
          </span>
        </div>
        <b-collapse
          id="rba"
          :visible="retailerBillingAddressCollapse"
        >
          <div>
            <address-form
              :countries="countries"
              :address-type="ADDRESSES_TYPE.BILLING"
              :address-data="editingShipment.billingAddressForRetailer"
              :disabled-all-fields="true"
            />
          </div>
        </b-collapse>
      </div>

      <!-- Retailer's shipping address -->
      <div class="mt-3">
        <div class="content cursor-pointer mb-2">
          <span
            class="d-flex align-items-center"
            @click="
              retailerShippingAddressCollapse = !retailerShippingAddressCollapse
            "
          >
            <caret-svg-icon
              :class="retailerShippingAddressCollapse ? 'down' : 'right'"
            />
            <span class="address-label font-poppins">
              Retailer’s Shipping Address
            </span>
          </span>
        </div>
        <b-collapse
          id="rsa"
          :visible="retailerShippingAddressCollapse"
        >
          <div>
            <address-form
              :countries="countries"
              :address-type="ADDRESSES_TYPE.SHIPPING"
              :address-data="editingShipment.shippingAddressForRetailer"
              :disabled-all-fields="true"
            />
          </div>
        </b-collapse>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { VBToggle, BCollapse } from 'bootstrap-vue'
import CaretSvgIcon from '@/assets/images/svg/CaretSvgIcon.vue'
import AddressForm from '@/components/address-form/AddressForm.vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import {
  ADDRESSES_TYPE,
} from '@/constants'
import { mapState } from 'vuex'
import { FETCH_COMMON } from '@/store/modules/common.module'

export default {
  name: 'EditAddresses',
  components: {
    BCollapse,
    CaretSvgIcon,
    AddressForm,
    EditSummaryCollapseIcon,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  data() {
    return {
      isVisible: true,
      brandBillingAddressCollapse: false,
      brandShippingAddressCollapse: false,
      retailerBillingAddressCollapse: false,
      retailerShippingAddressCollapse: false,
      countries: [],
      ADDRESSES_TYPE,
    }
  },
  computed: {
    ...mapState({
      editingShipment: state => state.shipments.editingShipment,
    }),
  },
  created() {
    this.getCountries()
  },
  methods: {
    getCountries() {
      this.$store.dispatch(FETCH_COMMON).then(res => {
        this.countries = res.COUNTRIES
      })
    },
  },
}
</script>
