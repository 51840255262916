<template>
  <div class="date-sidebar">
    <template>
      <p class="date-context">
        You have changed the shipment status to “Shipment In Transit”.
        Please provide the Retailer with the shipped date.
      </p>
      <p>Shipped Date</p>
    </template>
    <k-form-input
      v-model="date"
      class="date-input"
      type="date"
    />
    <div class="d-flex mt-4 flex-row w-100">
      <k-button
        variant="info"
        class="me-4"
        block
        @click="updateDate"
      >
        Update
      </k-button>
      <k-button
        variant="outline-info"
        block
        @click="closeSideBar"
      >
        Cancel
      </k-button>
    </div>
  </div>
</template>

<script>
import { KButton, KFormInput } from '@kingpin-global/kingpin-ui'
import { mapState } from 'vuex'
import { CLEAR_STATE } from '@/store/modules/sidebar.module'
import { formatDateForInput, formattedDate } from '@/@core/utils/format'

export default {
  components: {
    KButton,
    KFormInput,
  },
  data() {
    return {
      date: null,
    }
  },
  computed: {
    ...mapState({
      editingShipment: state => state.shipments.editingShipment,
    }),
  },
  mounted() {
    this.getDate()
  },
  emits: ['dynamic-emits'],
  methods: {
    getDate() {
      this.date = this.editingShipment.shippedDate ? formatDateForInput(formattedDate(this.editingShipment.shippedDate)) : ''
    },
    closeSideBar() {
      if (!this.date) {
        this.$emit('dynamic-emits', 'close')
        return
      }
      this.$store.commit(CLEAR_STATE)
    },
    updateDate() {
      if (!this.date) {
        this.$emit('dynamic-emits', 'error')
        return
      }
      this.editingShipment.shippedDate = new Date(this.date)
      this.$store.commit(CLEAR_STATE)
    },
  },

}
</script>
