<template>
  <div v-if="editingShipment">
    <div
      v-b-toggle.collapse-edit-packing-list-details
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span> Packing List Details </span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      v-if="triggerCollapse"
      id="collapse-edit-packing-list-details"
      v-model="isVisible"
      class="card-body-container"
    >
      <div
        v-if="isFetchingPackingList"
        class="spinner-body"
      >
        <b-spinner />
      </div>
      <div v-else>
        <div class="w-100 d-flex flex-column">
          <div class="d-flex flex-row justify-content-between text-start mb-2">
            <k-form-group
              label-content="Type of Parcel"
              label-class="font-work-sans"
              class="w-50 me-1"
            >
              <b-dropdown
                class="w-100 mt-1 k-search-dropdown dropdown-custom-class"
                :class="packingInfo.parcelType ? 'has-value' : ''"
                variant="none"
                :disabled="(enableCreatePackingList && !!packingDetailList.length)"
                toggle-class="drop-caret-icon"
                :text="
                  packingInfo.parcelType ||
                    'Select Parcel Type'
                "
              >
                <b-dropdown-item
                  v-for="option in Object.values(SHIPMENT_PARCEL_TYPE)"
                  :key="option"
                  :active="packingInfo.parcelType === option"
                  @click="packingInfo.parcelType=option"
                >
                  <span>
                    {{ option }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
            <k-form-group
              label-content="Measurements Unit"
              label-class="font-work-sans"
              class="w-50 ms-1"
            >
              <b-dropdown
                class="w-100 mt-1 k-search-dropdown dropdown-custom-class"
                :class="packingInfo.measurementsUnit ? 'has-value' : ''"
                variant="none"
                :disabled="(enableCreatePackingList && !!packingDetailList.length)"
                toggle-class="drop-caret-icon"
                :text="
                  packingInfo.measurementsUnit ||
                    'Select Measurements Unit'
                "
              >
                <b-dropdown-item
                  v-for="option in Object.values(MEASUREMENTS_UNIT)"
                  :key="option"
                  :active="packingInfo.measurementsUnit === option"
                  @click="packingInfo.measurementsUnit=option"
                >
                  <span>
                    {{ option }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
        </div>
        <template v-if="enableCreatePackingList">
          <div
            v-for="(packingDetail, index) in packingDetailList"
            :key="index"
          >
            <validation-observer
              #default="{ invalid }"
            >
              <div
                v-if="packingDetailProps[index]"
                class="d-flex align-item-center justify-content-between content cursor-pointer mt-2 mb-3"
              >
                <div class="address-label font-poppins">
                  <span @click="togglePackingListCollapse(index)">
                    <caret-svg-icon :class="packingDetailProps[index].collapse ? 'down' : 'right'" />
                    &nbsp;<span class="text-capitalize">{{ packingInfo.parcelType }} {{ index + 1 }}</span>
                  </span>
                </div>
                <div class="d-flex align-items-center">
                  <k-button
                    v-if="packingDetailProps[index].collapse"
                    class="me-2"
                    variant="outline-danger"
                    size="sm"
                    @click="deleteParcel(index, packingDetail._id)"
                  >
                    <parcel-delete-icon class="me-1" /> {{ packingDetailProps[index].isDeleting ? 'Deleting' : 'Delete' }}
                  </k-button>
                  <k-button
                    v-if="packingDetailProps[index].collapse && packingDetail._id"
                    class="me-2"
                    variant="outline-secondary"
                    size="sm"
                    @click="duplicateParcel(index)"
                  >
                    {{ packingDetailProps[index].isCreatingDuplicate ? 'Creating' : 'Duplicate' }}
                  </k-button>
                  <k-button
                    v-if="packingDetailProps[index].collapse"
                    variant="secondary"
                    size="sm"
                    :disabled="invalid"
                    @click="saveParcel(index)"
                  >
                    {{ packingDetailProps[index].isSaving ? 'Saving' : 'Save' }}
                  </k-button>
                </div>
              </div>
              <b-collapse :visible="packingDetailProps[index] && packingDetailProps[index].collapse">
                <div class="d-flex flex-md-wrap flex-lg-nowrap flex-row mb-3">
                  <div>
                    <validation-provider
                      #default="{ errors }"
                      name="Length"
                      vid="length"
                      rules="required|between:0.01,10000"
                    >
                      <k-form-group
                        label-content="Length"
                        label-class="font-work-sans"
                        class="m-1"
                        :form-invalid-content="errors[0]"
                        :state="errors.length > 0 ? false : null"
                      >
                        <k-form-input
                          v-model="packingDetail.length"
                          type="number"
                          name="length"
                          placeholder="Length"
                        />
                      </k-form-group>
                    </validation-provider>
                  </div>
                  <div>
                    <validation-provider
                      #default="{ errors }"
                      name="Width"
                      vid="width"
                      rules="required|between:0.01,10000"
                    >
                      <k-form-group
                        label-content="Width"
                        label-class="font-work-sans"
                        class="m-1"
                        :form-invalid-content="errors[0]"
                        :state="errors.length > 0 ? false : null"
                      >
                        <k-form-input
                          v-model="packingDetail.width"
                          type="number"
                          name="width"
                          placeholder="Width"
                        />
                      </k-form-group>
                    </validation-provider>
                  </div>
                  <div>
                    <validation-provider
                      #default="{ errors }"
                      name="Height"
                      vid="height"
                      rules="required|between:0.01,10000"
                    >
                      <k-form-group
                        label-content="Height"
                        label-class="font-work-sans"
                        class="m-1"
                        :form-invalid-content="errors[0]"
                        :state="errors.length > 0 ? false : null"
                      >
                        <k-form-input
                          v-model="packingDetail.height"
                          type="number"
                          name="height"
                          placeholder="Height"
                        />
                      </k-form-group>
                    </validation-provider>
                  </div>
                  <div>
                    <validation-provider
                      #default="{ errors }"
                      name="Weight"
                      vid="weight"
                      rules="required|between:0.01,10000"
                    >
                      <k-form-group
                        label-content="Weight (in kg)"
                        label-class="font-work-sans"
                        class="m-1"
                        :form-invalid-content="errors[0]"
                        :state="errors.length > 0 ? false : null"
                      >
                        <k-form-input
                          v-model="packingDetail.weight"
                          type="number"
                          name="weight"
                          placeholder="Weight (in kg)"
                        />
                      </k-form-group>
                    </validation-provider>
                  </div>
                  <div v-if="packingInfo.parcelType===SHIPMENT_PARCEL_TYPE.PALLET">
                    <validation-provider
                      #default="{ errors }"
                      name="No of Boxes"
                      vid="noOfBoxes"
                      rules="required|between:1,1000"
                    >
                      <k-form-group
                        label-content="No of Boxes"
                        label-class="font-work-sans"
                        class="m-1"
                        :form-invalid-content="errors[0]"
                        :state="errors.length > 0 ? false : null"
                      >
                        <k-form-input
                          v-model="packingDetail.noOfBoxes"
                          type="number"
                          name="noOfBoxes"
                          placeholder="No of Boxes"
                        />
                      </k-form-group>
                    </validation-provider>
                  </div>
                </div>
              </b-collapse>
            </validation-observer>
          </div>
        </template>
      </div>
      <k-button
        variant="outline-secondary"
        :disabled="!enabledAddParcel"
        class="mt-3"
        block
        @click="addParcel"
      >
        <loader-icon
          v-if="isSavingTypeAndMeasurements"
        />
        <div v-else>
          <parcel-add-icon class="me-1" /><span> Add a Parcel</span>
        </div>
      </k-button>
    </b-collapse>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { KButton, KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { VBToggle, BCollapse, BSpinner } from 'bootstrap-vue'
import CaretSvgIcon from '@/assets/images/svg/CaretSvgIcon.vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import ParcelAddIcon from '@/assets/images/svg/ParcelAddIcon.vue'
import ParcelDeleteIcon from '@/assets/images/svg/ParcelDeleteIcon.vue'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import {
  CREATE_PACKING_LIST, DELETE_PACKING_LIST, PATCH_SHIPMENT, SET_IS_PACKING_LIST_PRESENT, UPDATE_PACKING_LIST,
} from '@/store/modules/shipment.module'
import { mapState } from 'vuex'
import store from '@/store'
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import { LoaderIcon } from 'vue-feather-icons'
import { required, between } from '../../@core/utils/validations/validations'

const { SHIPMENT_PARCEL_TYPE, MEASUREMENTS_UNIT } = constants

export default {
  name: 'EditPackingListDetails',
  components: {
    BCollapse,
    CaretSvgIcon,
    EditSummaryCollapseIcon,
    ParcelAddIcon,
    ParcelDeleteIcon,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    KButton,
    KFormGroup,
    KFormInput,
    LoaderIcon,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    packingInfoDetails: {
      type: Object,
      default: () => ({}),
    },
    loadShipmentDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isVisible: true,
      SHIPMENT_PARCEL_TYPE,
      MEASUREMENTS_UNIT,
      triggerCollapse: true,
      packingInfo: { parcelType: '', measurementsUnit: '' },
      packingDetailList: [],
      packingDetailListLocal: [],
      packingDetailProps: [],
      isFetchingPackingList: false,
      isSavingTypeAndMeasurements: false,
    }
  },
  computed: {
    ...mapState({
      editingShipment: state => state.shipments.editingShipment,
    }),
    required() {
      return required
    },
    between() {
      return between
    },
    enableCreatePackingList() {
      return !!(Object.keys(this.packingInfo).length && this.packingInfo.parcelType && this.packingInfo.measurementsUnit)
    },
    enabledAddParcel() {
      return !!(this.packingInfo.parcelType && this.packingInfo.measurementsUnit)
    },
  },
  watch: {
    packingInfoDetails: {
      handler() {
        this.initParcelData(this.packingInfoDetails)
      },
      deep: true,
    },
  },
  created() {
    this.initParcelData(this.packingInfoDetails)
  },
  methods: {
    initParcelData(packingInfo) {
      this.packingDetailProps = []
      this.packingDetailListLocal = []
      this.packingInfo.parcelType = packingInfo.parcelType || null
      this.packingInfo.measurementsUnit = packingInfo.measurementsUnit || null
      if (packingInfo.packingDetailList && packingInfo.packingDetailList.length) {
        this.packingDetailList = packingInfo.packingDetailList
        this.packingDetailListLocal = JSON.parse(JSON.stringify(packingInfo.packingDetailList))
        this.packingDetailList.forEach(() => {
          this.packingDetailProps.push(this.newPackingDetailProps())
        })
        this.$store.commit(SET_IS_PACKING_LIST_PRESENT, true)
      }
      else {
        this.packingDetailList = []
        this.packingDetailProps.push(this.newPackingDetailProps())
        this.$store.commit(SET_IS_PACKING_LIST_PRESENT, false)
      }
    },
    saveParcelTypeAndMeasurements() {
      this.isSavingTypeAndMeasurements = true
      this.$store.dispatch(PATCH_SHIPMENT, { shipmentId: this.editingShipment._id, payload: this.packingInfo })
        .then(() => {
          this.isSavingTypeAndMeasurements = false
          apiToastSuccessV2('Parcel type and Measurements have been saved!')
          this.packingDetailProps.push(this.newPackingDetailProps())
          this.packingDetailList.push(this.newPackingDetail())
        })
        .catch(err => {
          this.isSavingTypeAndMeasurements = false
          apiToastErrorV2(err)
        })
    },
    addParcel() {
      if (!(this.packingDetailList.length)) {
        this.saveParcelTypeAndMeasurements()
      } else {
        this.packingDetailProps.push(this.newPackingDetailProps())
        this.packingDetailList.push(this.newPackingDetail())
      }
    },
    togglePackingListCollapse(index) {
      this.packingDetailProps[index].collapse = !this.packingDetailProps[index].collapse
    },
    // new Packing detail default data
    newPackingDetail() {
      const parcelModel = {
        width: null,
        length: null,
        height: null,
        weight: null,
      }
      if (this.packingInfo.parcelType === SHIPMENT_PARCEL_TYPE.PALLET) {
        parcelModel.noOfBoxes = null
      }
      return parcelModel
    },
    newPackingDetailProps() {
      return {
        collapse: true,
        isSaving: false,
        isDeleting: false,
        isCreatingDuplicate: false,
      }
    },
    async createParcel(index, payloadData) {
      await store.dispatch(CREATE_PACKING_LIST, payloadData)
        .then(() => {
          this.packingDetailProps[index].isSaving = false
          apiToastSuccessV2('Parcel created successfully!')
          this.loadShipmentDetails()
        })
        .catch(() => {
          apiToastErrorV2(err)
          this.packingDetailProps[index].isSaving = false
        })
    },
    saveParcel(index) {
      this.packingDetailProps[index].isSaving = true
      const payload = this.packingDetailList[index]
      this.parsePayload(payload)
      const payloadData = {
        shipmentId: this.editingShipment._id,
        payload,
      }
      if (payload._id) {
        payloadData.packingListId = payload._id
        store.dispatch(UPDATE_PACKING_LIST, payloadData)
          .then(() => {
            this.packingDetailProps[index].isSaving = false
            apiToastSuccessV2('Parcel updated successfully!')
            this.loadShipmentDetails()
          })
          .catch(() => {
            apiToastErrorV2(err)
            this.packingDetailProps[index].isSaving = false
          })
      } else {
        this.createParcel(index, payloadData)
      }
    },
    async duplicateParcel(index) {
      this.packingDetailProps[index].isCreatingDuplicate = true
      const payload = { ...this.packingDetailList[index] }
      this.parsePayload(payload)
      delete payload._id
      const payloadData = {
        shipmentId: this.editingShipment._id,
        payload,
      }
      await this.createParcel(index, payloadData)
      this.packingDetailProps[index].isCreatingDuplicate = false
    },
    deleteParcel(index, id) {
      if (id) {
        this.packingDetailProps[index].isDeleting = true
        const payload = {
          shipmentId: this.editingShipment._id,
          packingListId: id,
        }
        store.dispatch(DELETE_PACKING_LIST, payload)
          .then(() => {
            this.loadShipmentDetails()
            apiToastSuccessV2('Parcel deleted successfully!')
          })
          .catch(() => {
            this.packingDetailProps[index].isDeleting = false
          })
      } else {
        this.packingDetailProps.splice(index, 1)
        this.packingDetailList.splice(index, 1)
      }
    },
    parsePayload(payload) {
      payload.length = parseFloat(payload.length)
      payload.width = parseFloat(payload.width)
      payload.height = parseFloat(payload.height)
      payload.weight = parseFloat(payload.weight)
      if (this.packingInfo.parcelType === SHIPMENT_PARCEL_TYPE.PALLET) {
        payload.noOfBoxes = parseInt(payload.noOfBoxes)
      }
    },
  },
}
</script>
