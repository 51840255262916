<template>
  <div>
    <div
      v-b-toggle.collapse-documents
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span> Documents </span>
        <span>
          <edit-summary-collapse-icon
            :class="isVisible ? 'top-arrow' : 'down-arrow'"
          />
        </span>
      </p>
    </div>
    <b-collapse
      id="collapse-documents"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="mb-2">
        <div class="content cursor-pointer">
          <span
            class="d-flex align-items-center"
            @click="generatedCollapse = !generatedCollapse"
          >
            <caret-svg-icon :class="generatedCollapse ? 'down' : 'right'" />
            <span class="address-label font-poppins"> Generated </span>
          </span>
        </div>
        <b-collapse
          id="generated_docs"
          :visible="generatedCollapse"
        >
          <div class="text-start">
            <div>
              <span class="sub-header font-work-sans">
                Packing List’s Document
              </span>
              <div v-if="isPackingListPresent">
                <span class="sub-header font-work-sans mt-1">XLSX format</span>
                <div>
                  <file-item
                    :file-name="`Packing-List-${editingShipment.shipmentSeqId}`"
                    hide-delete
                    @on-download="onClickDownloadPackingListDoc()"
                  />
                </div>
                <span class="sub-header font-work-sans mt-1">PDF format</span>
                <div>
                  <file-item
                    :file-name="`Packing-List-${editingShipment.shipmentSeqId}`"
                    hide-delete
                    @on-download="onClickDownloadPackingListPdf()"
                  />
                </div>
              </div>
              <div v-else>
                <small class="ms-3">No documents found</small>
              </div>
            </div>
            <div>
              <span class="sub-header font-work-sans"> Invoice Document </span>
              <div v-if="editingShipment">
                <div>
                  <file-item
                    :file-name="`${editingShipment.shipmentSeqId}.pdf`"
                    hide-delete
                    @on-download="onClickDownloadInvoice(editingShipment)"
                  />
                </div>
              </div>
              <div v-else>
                <small class="ms-3">No documents found</small>
              </div>
            </div>
            <div>
              <span class="sub-header font-work-sans"> Delivery Document’s Proof </span>
              <div v-if="editingShipment">
                <div>
                  <file-item
                    :file-name="`Delivery-Document-Proof-${editingShipment.shipmentSeqId}.pdf`"
                    hide-delete
                    @on-download="onClickDownloadDeliveryProof(editingShipment)"
                  />
                </div>
              </div>
              <div v-else>
                <small class="ms-3">No documents found</small>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="mb-2">
        <div class="content cursor-pointer">
          <span
            class="d-flex align-items-center"
            @click="uploadedCollapse = !uploadedCollapse"
          >
            <caret-svg-icon :class="uploadedCollapse ? 'down' : 'right'" />
            <span class="address-label font-poppins"> Uploaded </span>
          </span>
        </div>
        <b-collapse
          id="uploaded_docs"
          :visible="uploadedCollapse"
        >
          <div class="text-start">
            <div>
              <span class="sub-header font-work-sans">
                Delivery Document’s Proof
              </span>
              <div v-if="deliveryProofDocs.length">
                <div
                  v-for="(item, index) in deliveryProofDocs"
                  :key="item.name + index"
                >
                  <file-item
                    :file-name="item.name"
                    @on-delete="addOrDeleteProofOfDocument(item.url, true)"
                    @on-download="onClickDownloadDeliveryProof(item)"
                  />
                </div>
              </div>
              <div v-else>
                <small class="ms-3">No documents found</small>
              </div>
            </div>
          </div>
          <div class="text-start">
            <div>
              <span class="sub-header font-work-sans">
                Invoice Uploaded By Brand
              </span>
              <div v-if="invoiceUrl">
                <file-item
                  :file-name="getFileName(FILE_TYPES.INVOICE_BY_BRAND, invoiceUrl)"
                  hide-delete
                  @on-download="download(invoiceUrl)"
                />
              </div>
              <div v-else>
                <small class="ms-3">No documents found</small>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        v-if="uploadedFile"
        class="w-100 mt-2"
      >
        <div class="uploaded-file">
          {{ textTruncate(uploadedFile.name) }}
          <span
            class="float-end cursor-pointer"
            @click="uploadedFile = null"
          >
            <parcel-delete-icon />
          </span>
        </div>
        <k-button
          variant="secondary"
          block
          :disabled="isSaving"
          @click="onUploadFile"
        >
          {{ isSaving ? 'Saving...' : 'Save' }}
        </k-button>
      </div>
      <div
        v-else
        class="mt-2 upload-file-button"
      >
        <label
          for="upload_files"
          class="upload-files d-flex justify-content-center align-items-center"
        >
          <!-- upload icon -->
          <upload-icon />
          <span class="button-text font-inter">
            Upload files from your system
          </span>
        </label>

        <b-form-file
          id="upload_files"
          v-model="uploadedFile"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          plain
          hidden="true"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import CaretSvgIcon from '@/assets/images/svg/CaretSvgIcon.vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import ParcelDeleteIcon from '@/assets/images/svg/ParcelDeleteIcon.vue'
import UploadIcon from '@/assets/images/svg/UploadIcon.vue'
import {
  download,
  downloadPDF,
  getBase64FromBuffer,
  getFileName,
  getFileNameAndExtension,
  textTruncate,
} from '@/common-utils'
import FileItem from '@/components/file-item/FileItem.vue'
import { FILE_FORMATS, FILE_TYPES } from '@/constants'
import { DOWNLOAD_FILE, UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import { PATCH_SHIPMENT } from '@/store/modules/shipment.module'
import { KButton } from '@kingpin-global/kingpin-ui'
import {
  BCollapse, BFormFile,
  VBToggle,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  name: 'DocumentList',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    CaretSvgIcon,
    FileItem,
    UploadIcon,
    BFormFile,
    KButton,
    ParcelDeleteIcon,
  },
  directive: {
    'v-b-toggle': VBToggle,
  },
  data() {
    return {
      isVisible: true,
      generatedCollapse: true,
      uploadedCollapse: true,
      uploadedFile: null,
      deliveryProofDocs: [],
      isSaving: false,
      invoiceUrl: null,
      FILE_TYPES,
      getFileName,
      download,
      textTruncate,
    }
  },
  computed: {
    ...mapState({
      editingShipment: state => state.shipments.editingShipment,
      isPackingListPresent: state => state.shipments.isPackingListPresent,
    }),
  },
  created() {
    if (this.editingShipment?.invoiceUrl) {
      this.invoiceUrl = this.editingShipment?.invoiceUrl || null
    }
    if (this.editingShipment.deliveryProofDocuments) {
      this.editingShipment.deliveryProofDocuments.forEach(proof => {
        this.setDeliveryProofDocument(proof)
      })
    }
  },
  methods: {
    onClickDownloadPackingListDoc() {
      const params = {
        fileType: FILE_FORMATS.XLSX,
      }
      try {
        const payload = {
          url: this.$kpParams(this.$kpEndpoint.shipment.downloadPackingList, this.editingShipment._id),
          fileFormat: FILE_FORMATS.XLSX,
          params,
          fileName: `Packing-List-${this.editingShipment?.shipmentId || this.editingShipment?._id}`,
          successMessage: 'Your Packing List is downloaded for this shipment',
        }
        this.$store.dispatch(DOWNLOAD_FILE, payload)
      } catch (err) {
        apiToastErrorV2(err)
      }
    },
    async onClickDownloadPackingListPdf() {
      const params = {
        fileType: FILE_FORMATS.PDF,
      }
      try {
        const payload = {
          url: this.$kpParams(this.$kpEndpoint.shipment.downloadPackingList, this.editingShipment._id),
          params,
        }
        const res = await this.$store.dispatch(DOWNLOAD_FILE, payload)
        const base64String = getBase64FromBuffer(res?.data?.data?.fileStream?.data)
        downloadPDF({
          pdf: base64String || '',
          fileName: `Packing-List-${this.editingShipment.shipmentSeqId}.pdf`,
        })
        apiToastSuccessV2('Your Packing List is downloaded for this shipment')
      } catch (err) {
        apiToastErrorV2(err)
      }
    },
    onClickDownloadDeliveryProof(item) {
      if (item.url) {
        download(item.url, item.name)
      } else {
        this.downloadDeliveryProof(item)
      }
    },
    async downloadDeliveryProof(item) {
      try {
        const payload = {
          url: this.$kpParams(this.$kpEndpoint.shipment.downloadDeliveryProof, item._id),
        }
        const res = await this.$store.dispatch(DOWNLOAD_FILE, payload)
        const base64String = getBase64FromBuffer(res?.data?.data?.data)
        downloadPDF({
          pdf: base64String || '',
          fileName: `Delivery-Document-Proof-${item.shipmentSeqId || item._id}`,
        })
        apiToastSuccessV2(`Your Delivery Proof is downloaded for the shipment ${item.shipmentSeqId}`)
      } catch (err) {
        apiToastErrorV2(err)
      }
    },
    onClickDownloadInvoice(item) {
      try {
        const payload = {
          url: this.$kpParams(this.$kpEndpoint.shipment.downloadInvoice, item._id),
          fileFormat: FILE_FORMATS.PDF,
          headers: {
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
          },
          fileName: `${item.shipmentSeqId || item._id}-Invoice`,
          successMessage: `Your invoice is downloaded for the shipment ${item.shipmentSeqId}`,
        }
        this.$store.dispatch(DOWNLOAD_FILE, payload)
      } catch (err) {
        apiToastErrorV2(err)
      }
    },
    async onUploadFile() {
      this.isSaving = true
      if (this.uploadedFile instanceof File) {
        const filenameWithExtension = getFileNameAndExtension(this.uploadedFile.name)
        const formData = new FormData()
        formData.append('file', this.uploadedFile, `${this.getDeliveryProofFileName()}.${filenameWithExtension.extension}`)
        const url = await this.$store.dispatch(UPLOAD_DOCUMENTS, { fileType: FILE_TYPES.DELIVERY_PROOF, payload: formData })
        this.addOrDeleteProofOfDocument(url)
      } else {
        apiToastErrorV2('File not supported')
        this.isSaving = false
      }
    },
    setDeliveryProofDocument(proof) {
      const filenameWithExtension = getFileNameAndExtension(proof)
      const fileName = `${filenameWithExtension.fileName}.${filenameWithExtension.extension}`
      this.deliveryProofDocs.push({ url: proof, name: fileName })
    },
    getDeliveryProofFileName() {
      const availableDocs = this.deliveryProofDocs
      const index = availableDocs.length + 1
      let generatedFileName = `Delivery-Document-Proof${index}-${this.editingShipment.shipmentSeqId}`
      for (let i = 0; i < availableDocs.length; i++) {
        const filenameWithExtension = getFileNameAndExtension(availableDocs[i].url)
        if (filenameWithExtension.fileName === generatedFileName) {
          generatedFileName = `Delivery-Document-Proof${index + 1}-${this.editingShipment.shipmentSeqId}`
          break
        }
      }
      return generatedFileName
    },
    addOrDeleteProofOfDocument(url, isDelete = false) {
      const payload = {
        deliveryProofDocument: url,
      }
      this.$store.dispatch(PATCH_SHIPMENT, { payload, shipmentId: this.editingShipment._id })
        .then(res => {
          if (isDelete) {
            this.deliveryProofDocs = this.deliveryProofDocs.filter(doc => doc.url !== url)
          }
          else {
            this.setDeliveryProofDocument(url)
          }
          apiToastSuccessV2(res.data.message)
          this.isSaving = false
          this.uploadedFile = null
        })
        .catch(err => {
          this.isSaving = false
          apiToastErrorV2(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/@core/scss/vue/pages/kp-document-list.scss";
</style>
