<template>
  <div>
    <div
      v-b-toggle.collapse-edit-summary
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span> Shipment Summary </span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse-edit-summary"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column">
        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Shipment Status"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="editingShipment.status ? 'has-value' : ''"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="SHIPMENT_TEXT[editingShipment.status] || 'Select a status'"
                :disabled="editingShipment.status === SHIPMENT_STATUS.READY_FOR_COLLECTION"
              >
                <b-dropdown-item
                  v-for="option in getShipmentStatuses"
                  :key="option"
                  :active="editingShipment.status === option"
                  @click="onSelectStatus(option)"
                >
                  <span>
                    {{ SHIPMENT_TEXT[option] }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Price Multiplier"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="editingShipment.discount"
                type="text"
                name="discount"
                placeholder="Price Multiplier"
              />
            </k-form-group>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Created Date"
              label-class="font-work-sans"
            >
              <k-form-input
                type="text"
                name="created-at"
                :value="formattedDate(editingShipment.createdAt)"
                placeholder="Created Date"
                disabled
              />
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Logistic Terms"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="editingShipment.logisticsTerms.length ? 'has-value' : ''"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="editingShipment.logisticsTerms || 'Select a logistics terms'"
              >
                <b-dropdown-item
                  v-for="option in Object.values(LOGISTICS_TERMS)"
                  :key="option"
                  :active="editingShipment.logisticsTerms === option"
                  @click="editingShipment.logisticsTerms = option"
                >
                  <span>
                    {{ option }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Total Price"
              label-class="font-work-sans"
            >
              <k-form-input
                type="text"
                name="total-price"
                :value="editingShipment.totalPriceAfterDiscount || editingShipment.totalPrice"
                placeholder="Total Price"
                disabled
              />
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Total Units"
              label-class="font-work-sans"
            >
              <k-form-input
                type="text"
                name="total-quantity"
                :value="editingShipment.totalQuantity"
                placeholder="Total Units"
                disabled
              />
            </k-form-group>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Total Price from supplier"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="editingShipment.totalPriceFromBrand"
                type="number"
                name="total-price"
                placeholder="Total Price from supplier"
              />
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Total units from supplier"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="editingShipment.totalQuantityFromBrand"
                type="number"
                name="total-quantity"
                placeholder="Total units from supplier"
              />
            </k-form-group>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Currency from supplier"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="currency || 'Select a currency'"
              >
                <b-dropdown-item
                  v-for="option in Object.values(CURRENCY_CONVERSIONS).map(currency => currency.LABEL)"
                  :key="option"
                  :active="currency === option"
                  @click="updateCurrency(option)"
                >
                  <span>
                    {{ option }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Delivered Date"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="editingShipment.deliveredDate"
                type="date"
                name="delivered-date"
                placeholder="Delivered Date"
              />
            </k-form-group>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { LOGISTICS_TERMS, CURRENCY_CONVERSIONS } from '@/constants'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { SET_EDITING_SHIPMENT_PROPERTY } from '@/store/modules/shipment.module'
import { formatDateForInput, formattedDate } from '@/@core/utils/format'
import { mapState } from 'vuex'
import {
  CLEAR_STATE, SET_COMPONENT, SET_FORM_ACTIONS, SET_SUB_TITLE, SET_TITLE, TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import DateSideBar from '@/views/shipment-management/DateSideBar.vue'
import { KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'

const { SHIPMENT_STATUS, SHIPMENT_TEXT } = constants

export default {
  name: 'EditSummary',
  components: {
    BCollapse,
    BDropdown,
    BDropdownItem,
    EditSummaryCollapseIcon,
    KFormGroup,
    KFormInput,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  data() {
    return {
      isVisible: true,
      SHIPMENT_TEXT,
      SHIPMENT_STATUS,
      LOGISTICS_TERMS,
      formattedDate,
      CURRENCY_CONVERSIONS,
      currency: '',
    }
  },
  computed: {
    ...mapState({
      editingShipment: state => state.shipments.editingShipment,
      sideBarTitile: state => state.sidebar.title,
    }),
    getShipmentStatuses() {
      const shipmentStatuses = SHIPMENT_STATUS
      if (this.editingShipment.status !== SHIPMENT_STATUS.READY_FOR_COLLECTION) {
        delete shipmentStatuses.READY_FOR_COLLECTION
      }
      return shipmentStatuses
    },
  },
  emits: ['update-message'],
  watch: {
    editingShipment: {
      handler() {
        this.editingShipment.deliveredDate = this.editingShipment.deliveredDate ? formatDateForInput(formattedDate(this.editingShipment.deliveredDate)) : ''
      },
      deep: true,
    },
  },
  created() {
    this.currency = this.editingShipment.currencyFromBrand || ''
    this.editingShipment.deliveredDate = this.editingShipment.deliveredDate ? formatDateForInput(formattedDate(this.editingShipment.deliveredDate)) : ''
  },
  methods: {
    async onSelectStatus(option) {
      this.$store.commit(SET_EDITING_SHIPMENT_PROPERTY, { property: 'status', value: option })
      await this.$store.commit(CLEAR_STATE)
      if (option === SHIPMENT_STATUS.SHIPMENT_IN_TRANSIT) {
        this.updateShippedDate()
      }
    },
    updateShippedDate() {
      this.$store.commit(SET_TITLE, 'Shipped Date')
      this.$store.commit(SET_FORM_ACTIONS, this.formActions)
      this.$store.commit(SET_COMPONENT, DateSideBar)
      this.$store.commit(SET_SUB_TITLE, `Shipment ID: ${this.editingShipment.shipmentSeqId}`)
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    updateCurrency(currency) {
      this.currency = currency
      this.editingShipment.currencyFromBrand = currency
    },
    formActions(actionType) {
      switch (actionType) {
        case 'error':
          this.$emit('update-message')
          break
        default:
          this.$store.commit(CLEAR_STATE)
          break
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables/variables-components";
@import '@/assets/scss/common-mixin-functions.scss';

.reject-dropdown {
    .btn {
        padding: 8px 24px;
        &::after {
            margin-left: 8px !important;
        }
    }
    .dropdown-menu {
      .dropdown-item {
         @include font-weight-and-size(400, 14px);
          line-height: 16px;
          color: $label-clr !important;
          &:hover {
           background: $dropdown-hover-bg !important;
          }
        }
  }
}
</style>
